@import url('https://fonts.googleapis.com/css2?family=Benne&display=swap');

.product {
    font-family: Almarai;
}

.ppp {
    display: flex;
}

.product_left {
    display: flex;
    padding-bottom: 70px;
    /* padding: 8px; */
    /* padding: 20px 20px; */
    justify-content: center;
}

.product_left>img {
    border-radius: 35px;
}

.product_middle {
    display: flex;
    /* padding: 20px 20px; */
    padding-top: 20px;
    padding-left: 80px;
}

.product_desc {
    display: block;
    padding-left: 80px;
    text-align: justify;
}

.product_mock {
    display: flex;
    padding-left: 80px;
    padding-top: 50px;
}

.product_reach {
    display: flex;
    padding-left: 90px;
    padding-top: 200px;
}

.product_reach1 {
    display: flex;
    padding-left: 80px;
    /* padding-top: 200px; */
}

.product_download {
    display: flex;
    padding-left: 90px;
    padding-top: 50px;
}

.download_image {
    height: 50px;

}

.app_download {
    display: flex;
    padding: 20px 20px;
    justify-content: space-evenly;

}

.googleplay {

    align-items: center;
}

.playstore {
    display: flex;
    justify-content: center;
}

.social {
    display: flex;
    justify-content: 80px;
    padding-left: 20px;
    padding: 10px;
}


.s {
    display: flex;
    padding-left: 70px;
}

.mockup {
    /* display: flex; */
    padding: 20px 20px;
    padding-left: 80px;
    justify-content: space-evenly;
}

.product_1 {
    font-family: Almarai;
    display: flex;
    justify-content: center;
    /* padding: 50px 50px; */
    padding: 60px;
    color: #BD932E;

}

.mockupimg {
    height: 400px;
    display: flex;
    padding: 20px 20px;

    justify-content: space-evenly;
}

.mockup>img {
    height: 400px;

}

.app_download>img {
    height: 5px;
    width: 10px;
}

.aboutproduct_left {
    display: flex;
    justify-content: flex-end;
    /* padding-bottom: 700px; */
}

.aboutproduct_logo {
    display: flex;
    align-items: flex-end;
}

.aboutproduct_left>img {
    height: 100px;
}

.aboutproduct_logo>img {
    height: 40px;
}

.aboutproduct_left {
    padding-right: 25px;
    padding-bottom: 25px;
}

.app-term-privacy {
    display: flex;
    justify-content: 80px;
    padding-left: 80px;
    color: var(--Black, #000);
    padding-top: 50px;
    font-family: Benne;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.app-term {
    cursor: pointer;
    color: var(--Black, #0C95E2);
    font-family: Benne;

    text-decoration-line: underline;
}

.app-privacy {
    cursor: pointer;
    color: var(--Black, #0C95E2);
    font-family: Benne;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

@media screen and (min-width: 992px) {
    /* body {
        background-color: blue;
    } */

    .mockup {
        display: flex;
        /* padding: 20px 20px;
        padding-left: 80px; */
        justify-content: space-evenly;

    }

    /* .mockupimg2 {
        height: 400px;
        display: flex; */
    /* padding: 20px 20px; */
    /* border-radius: 35px;
        justify-content: space-evenly;
    } */
    .mockupimg {
        height: 400px;
        display: flex;
        /* padding: 20px 20px; */
        border-radius: 35px;
        justify-content: space-evenly;
    }

    .app-term-privacy {
        display: flex;
        justify-content: 80px;
        padding-left: 0px;
        color: var(--Black, #000);
        padding-top: 50px;
        font-family: Benne;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .app-term {
        cursor: pointer;
        color: var(--Black, #0C95E2);
        font-family: Benne;

        text-decoration-line: underline;
    }

    .app-privacy {
        cursor: pointer;
        color: var(--Black, #0C95E2);
        font-family: Benne;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
    }
}

/* .mockupimg2 {
    height: 400px;
    display: flex; */
/* padding: 20px 20px; */
/* border-radius: 35px;
    justify-content: space-evenly;
} */

@media screen and (max-width: 600px) {
    .product_desc {
        /* display: block; */
        /* padding-left: 80px; */
        /* text-align: justify; */
        justify-content: center;
    }

    .app-term-privacy {
        display: flex;
        justify-content: 80px;
        padding-left: 0px;
        color: var(--Black, #000);
        padding-top: 50px;
        font-family: Benne;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .app-term {
        cursor: pointer;
        color: var(--Black, #0C95E2);
        font-family: Benne;

        text-decoration-line: underline;
    }

    .app-privacy {
        cursor: pointer;
        color: var(--Black, #0C95E2);
        font-family: Benne;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
    }
}



@media screen and (max-width: 580px) {


    .product_name {
        font-size: 25px;
        margin-left: -70px;
    }

    .product_align {
        margin-left: -70px;
    }

    .product_reach1 {
        margin-left: -70px;
    }

    .product_reach {
        margin-left: -70px;
    }

    .social {
        margin-left: -70px;
    }

    .product_left>img {
        border-radius: 35px;
        height: 200px;
    }

    .app-term-privacy {
        display: flex;
        justify-content: 80px;
        padding-left: 0px;
        color: var(--Black, #000);
        padding-top: 50px;
        font-family: Benne;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .app-term {
        cursor: pointer;
        color: var(--Black, #0C95E2);
        font-family: Benne;

        text-decoration-line: underline;
    }

    .app-privacy {
        cursor: pointer;
        color: var(--Black, #0C95E2);
        font-family: Benne;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
    }
}

@media screen and (max-width: 430px) {


    .product_name {
        font-size: 25px;
        margin-left: -70px;
    }

    .product_align {
        margin-left: -70px;
    }

    .product_reach1 {
        margin-left: -70px;
    }

    .product_reach {
        margin-left: -70px;
    }

    .social {
        margin-left: -70px;
    }

    .product_left>img {
        border-radius: 35px;
        height: 200px;
    }

    .app-term-privacy {
        display: flex;
        justify-content: 80px;
        padding-left: 0px;
        color: var(--Black, #000);
        padding-top: 50px;
        font-family: Benne;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .app-term {
        cursor: pointer;
        color: var(--Black, #0C95E2);
        font-family: Benne;

        text-decoration-line: underline;
    }

    .app-privacy {
        cursor: pointer;
        color: var(--Black, #0C95E2);
        font-family: Benne;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
    }
}

@media screen and (max-width: 390px) {


    .product_name {
        font-size: 20px;
        margin-left: -90px;
    }

    .product_align {
        margin-left: -90px;
    }

    .product_reach1 {
        margin-left: -90px;
    }

    .product_reach {
        margin-left: -90px;
    }

    .social {
        margin-left: -90px;
    }

    .product_left>img {
        border-radius: 35px;
        height: 100px;
    }

    .app-term-privacy {
        display: flex;
        justify-content: 80px;
        padding-left: 0px;
        color: var(--Black, #000);
        padding-top: 50px;
        font-family: Benne;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .app-term {
        cursor: pointer;
        color: var(--Black, #0C95E2);
        font-family: Benne;

        text-decoration-line: underline;
    }

    .app-privacy {
        cursor: pointer;
        color: var(--Black, #0C95E2);
        font-family: Benne;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
    }
}