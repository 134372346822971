.contact>h3 {
    padding-top: 200px;
    padding-right: px;
    color: #BD932E;
    font-family: Almarai;
}

.contact_left>h5 {
    font-family: Almarai;
}

.contact {
    display: flex;
    justify-content: center;

    text-align: justify;

}

.c {
    display: flex;
    justify-content: center;
    text-align: justify;
    padding-right: px;
}

.contact_left {
    display: flex;
    justify-content: center;
    /* padding-left: 80px; */

}


.email {
    font-size: large;
}

@media screen and (max-width:600px) {
    .contact_left {
        display: flex;
        justify-content: center;
        /* padding-left: 100px */
    }
}

/* @media screen and (max-width:600px) {


    .c {
        display: flex;
        justify-content: center;
        text-align: justify;
        padding-right: 92px;
    }

    .contact_left {
        display: flex;
        justify-content: center;
        padding-left: 75px;
        
    }

    .contact>h3 {
        padding-top: 300px;
        padding-right: 100px;
    }
}

@media screen and (max-width:992px) {


    .c {
        display: flex;
        justify-content: center;
        text-align: justify;
        padding-right: 92px;
    }

    .contact_left {
        display: flex;
        justify-content: center;
        padding-left: 65px;
      
    }

    .contact>h3 {
        padding-top: 300px;
        padding-right: 92px;
    }
}

@media screen and (max-width:1280px) {


    .c {
        display: flex;
        justify-content: center;
        text-align: justify;
        padding-right: 92px;
    }

    .contact_left {
        display: flex;
        justify-content: center;
        padding-left: 55px;
        
    }

    .contact>h3 {
        padding-top: 200px;
        padding-right: 100px;
    }
}

@media screen and (max-width:1520px) {


    .c {
        display: flex;
        justify-content: center;
        text-align: justify;
        padding-right: 92px;
    }

    .contact_left {
        display: flex;
        justify-content: center;
        padding-left: 55px;
       
    }

    .contact>h3 {
        padding-top: 200px;
        padding-right: 100px;
    }
}

@media screen and (max-width:1920px) {


    .c {
        display: flex;
        justify-content: center;
        text-align: justify;
        padding-right: 92px;
    }

    .contact_left {
        display: flex;
        justify-content: center;
        padding-left: 6px;
        
    }

    .contact>h3 {
        padding-top: 200px;
        padding-right: 100px;
    }
}

@media screen and (min-width:1920px) {


    .c {
        display: flex;
        justify-content: center;
        text-align: justify;
        padding-right: 92px;
    }

    .contact_left {
        display: flex;
        justify-content: center;
        padding-left: 55px;
        
    }

    .contact>h3 {
        padding-top: 200px;
        padding-right: 100px;
    }
} */