.aboutdotcom {
    text-align: justify;
}

.post_thumbnail {
    display: flex;
}

.work_media {
    /* padding: 20px 20px; */
    padding-bottom: 20px;
}

.work {
    display: flex;
    justify-content: center;
    /* padding: 30px 30px; */
}

.non-link {
    text-decoration: none;
    margin-left: 2px;
    margin-right: 2px;
}


.work_middle {
    font-family: Almarai;
    padding-left: 50px;
}

.work_desc {
    /* background-color: #194173; */
    display: block;
    max-width: 650px;
    padding-left: 50px;
    text-align: justify;
}

.work_1 {
    font-family: Almarai;
    display: flex;
    justify-content: center;
    padding: 50px 50px;
    /* padding: 60px; */
    color: #BD932E;
}



/* .work_left>img {
    border-radius: 35px;
}



.work_left {

    display: flex;
    justify-content: center;

} */

.work_left {
    display: flex;
    padding-bottom: 70px;
    /* padding: 8px; */
    /* padding: 20px 20px; */
    justify-content: center;
}

.work_left>img {
    border-radius: 35px;
}


.btn1 {

    font-family: Almarai;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #194173;
    background: linear-gradient(180deg, #BD932E 0%, #F1B91B 48.96%, #F3CC16 100%);
    /* color: linear-gradient(180deg, #BD932E 0%, #F1B91B 48.96%, #F3CC16 100%); */
    border: 2px solid #BD932E;
    text-decoration: none;
}

.btn1:hover {
    background-color: #BD932E;
    color: #fff;
}


@media screen and (min-width: 992px) {

    /* body {
        background-color: blue;
    } */
    .workDetail {
        padding: 10px;
        padding-top: 60px;
    }

    .work_media {
        display: flex;
    }


}

@media screen and (min-width:600px) {
    /* .work_media {
        display: flex;
    } */

    .mockup {
        display: flex;
    }

    .mockupimg {
        height: 400px;
        display: flex;
        /* padding: 20px 20px; */
        border-radius: 35px;
        justify-content: space-evenly;
    }

    /* .work_left {
        padding: 20px 20px;
    } */
    .work_left {
        padding: 40px 20px;
    }

    /* .work_left>img {
        height: 300px;
        width: 100%;
        border-radius: 35px;
    } */

}

@media screen and (max-width: 600px) {
    /* body {
        background-color: olive;
    } */

    /* .work_media {
        display: flex;
    } */
    /* 
    .img123 {
        height: 50px;
    } */

    /* .img-fluid {
        border-radius: 35px; */
    /* height: 80px; */
    /* } */
    .mockupimg {
        height: 400px;
        display: flex;
        /* padding: 20px 20px; */
        border-radius: 35px;
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 580px) {

    .work_desc {
        /* padding-left: -200px; */
        margin-left: -70px;
    }

    .work_title {
        /* background-color: #F1B91B; */
        /* padding-left: -200px; */
        margin-left: -70px;
    }

    .our_product {
        margin-left: -50px;
        /* background-color: antiquewhite; */
    }

    .work_left {
        /* background-color: #194173; */
        margin-right: 50px;
    }

    .work_left>img {
        border-radius: 35px;
        height: 100px;
    }
}

@media screen and (max-width: 430px) {

    .work_desc {
        /* padding-left: -200px; */
        margin-left: -70px;
    }

    .work_title {
        /* background-color: #F1B91B; */
        /* padding-left: -200px; */
        margin-left: -70px;
    }

    .our_product {
        margin-left: -50px;
        /* background-color: antiquewhite; */
    }

    .work_left {
        /* background-color: #194173; */
        margin-right: 50px;
    }

    .work_left>img {
        border-radius: 35px;
        height: 100px;
    }
}

@media screen and (max-width: 390px) {
    .work_desc {
        /* padding-left: -200px; */
        margin-left: -90px;
    }

    .work_title {
        /* background-color: #F1B91B; */
        /* padding-left: -200px; */
        margin-left: -90px;
    }

    .our_product {
        margin-left: -50px;
        /* background-color: antiquewhite; */
    }

    .work_left {
        /* background-color: #194173; */
        margin-right: 50px;
    }
}