.btn-get-started {
    font-family: Almarai;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #194173;
    /* linear-gradient(180deg, #D1A21D 7.81%, #F3CC16 100%); */

    background: linear-gradient(180deg, #BD932E 0%, #F1B91B 48.96%, #F3CC16 100%);
    /* color: linear-gradient(180deg, #BD932E 0%, #F1B91B 48.96%, #F3CC16 100%); */
    border: 2px solid #BD932E;
    text-decoration: none;
}

.btn-get-started:hover {
    background-color: #BD932E;
    color: #fff;
}

.animated {
    padding: 20px 0px;
}

.nav_bg {
    font-family: Almarai;
}

.nav_slogan>h1 {
    color: #BD932E;
}

@media screen and (min-width: 992px) {
    .nav_slogan {
        padding-top: 100px;

    }

    .nav_slogan>h1 {
        color: #BD932E;
    }
}