/* .navbar {
    background: linear-gradient(180deg, #D9D5C5 0%, rgba(217, 213, 197, 0) 100%);
} */

.nav-item {
    display: flex;
    padding: 0 80px;
    font-family: Almarai;
}



.navbar {
    /* background: linear-gradient(180deg, #D9D5C5 0%, rgba(217, 213, 197, 0) 100%);
    border-bottom: 1px solid #969595; */
    filter: drop-shadow(0px 4px 20px #969595);
    box-shadow: 0px 5px 8px -9px rgba(0, 0, 0, 0.75);
}

.active {
    color: #BD932E !important;

}

/* .active {
    border-bottom: 4px solid #BD932E;
    display: inline-block;
} */

.header_left>img {
    height: 100px;
}

.header_logo>img {
    height: 40px;

}

.header_logo {
    display: flex;
    align-items: center;
}

.header_left {
    display: flex;
    justify-content: space-evenly;

}