.about_middle {
    max-width: 650px;
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .about {
    text-align: justify;
    font-family: Almarai;
    padding: 30px;
} */

.about_1 {
    /* font-style: Almarai; */
    font-family: Almarai;
    display: flex;
    justify-content: center;
    padding: 0px 0px;
    color: #BD932E;
}

@media screen and (max-width: 580px) {
    .about {
        text-align: justify;
        font-family: Almarai;
        margin-left: -20px;
        margin-right: -20px;
        /* padding: 30px; */
    }
}

.content {
    display: flex;
    text-align: justify;
    justify-content: center;
    /* color: #194173; */
}

.about_left>img {
    height: 100px;
}

.about_logo>img {
    height: 40px;
}

.about_left {
    display: flex;
    justify-content: center;
}

.about_logo {
    display: flex;
    align-items: center;
}