.terms-and-conditions {
    padding: 15px 15px 15px 15px;
}

.privacy {
    padding: 15px 15px 15px 15px;
}

.text-font {
    color: var(--Black, #0C95E2);
    font-family: Benne;

    text-decoration-line: underline;
}

.wbs-privacy {
    padding: 15px;
}

.wbs-term {
    padding: 15px;
}

.cookiehead {
    color: var(--black, #000);
    flex-direction: column;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    justify-content: center;
    line-height: 24px;
}

.cookieheading {
    color: var(--black, #000);
    flex-direction: column;
    font-family: Work Sans;
    font-style: normal;
    font-size: 24px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: .2px;
    line-height: 24px;
}